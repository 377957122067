import * as THREE from "three";
import colors from "./colors";

// use MeshLambertMaterial for better performance, but no shadows
const Material = THREE.MeshPhongMaterial;

export const createSea = () => {
  // radius top, radius bottom, height, number of segments on the radius, number of segments vertically
  const geometry = new THREE.CylinderGeometry(800, 800, 800, 40, 15);
  const rotation = new THREE.Matrix4().makeRotationX(-Math.PI / 2);
  geometry.applyMatrix(rotation);
  geometry.mergeVertices();

  // must do rotation before getting vertices!!
  const waves = geometry.vertices.map(({ x, y, z }) => ({
    x,
    y,
    z,
    angle: Math.random() * Math.PI * 2,
    amplitude: 5 + Math.random() * 35,
    speed: 0.016 + Math.random() * 0.01
  }));

  const material = new Material({
    color: colors.blue,
    transparent: true,
    opacity: 0.7,
    flatShading: true
  });

  const sea = new THREE.Mesh(geometry, material);
  sea.receiveShadow = true;
  return { sea, waves };
};

const setEuler = (attribute, mesh, { x, y, z }) => {
  if (x) mesh[attribute].x = x;
  if (y) mesh[attribute].y = y;
  if (z) mesh[attribute].z = z;
};

const setPosition = setEuler.bind(null, "position");
const setRotation = setEuler.bind(null, "rotation");

export const createCloud = () => {
  const cloud = new THREE.Object3D();
  const cubeGeometry = new THREE.BoxGeometry(20, 20, 20);
  const cubeMaterial = new Material({
    color: colors.white,
    transparent: true,
    opacity: 0.9,
    flatShading: true
  });

  const parts = 3 + Math.floor(Math.random() * 3);
  Array.from({ length: parts }, (_, i) => ({
    position: {
      x: i * 15,
      y: Math.random() * 10,
      z: Math.random() * 10
    },
    rotation: {
      z: Math.random() * Math.PI * 2,
      y: Math.random() * Math.PI * 2
    },
    scale: 0.1 + Math.random() * 0.9
  })).forEach(({ position, rotation, scale }) => {
    const cube = new THREE.Mesh(cubeGeometry, cubeMaterial);
    setPosition(cube, position);
    setRotation(cube, rotation);
    cube.scale.set(scale, scale, scale);
    cube.castShadow = true;
    cube.receiveShadow = true;
    cloud.add(cube);
  });

  return cloud;
};

export const createSky = () => {
  const sky = new THREE.Object3D();
  const cloudCount = 30;
  const stepAngle = (Math.PI * 2) / cloudCount;
  Array.from({ length: cloudCount }, (_, i) => {
    const angle = stepAngle * i;
    const distanceFromCenter = 850 + Math.random() * 200;
    return {
      position: {
        y: Math.sin(angle) * distanceFromCenter,
        x: Math.cos(angle) * distanceFromCenter,
        z: -200 - Math.random() * 400
      },
      rotation: {
        z: angle + Math.PI / 2
      },
      scale: 1 + Math.random() * 2
    };
  }).forEach(({ position, rotation, scale }) => {
    const cloud = createCloud();
    setPosition(cloud, position);
    setRotation(cloud, rotation);
    cloud.scale.set(scale, scale, scale);
    sky.add(cloud);
  });

  return sky;
};

export const createFasinator = () => {
  const fascinator = new THREE.Object3D();

  const quaternion = new THREE.Quaternion();
  quaternion.setFromAxisAngle(new THREE.Vector3(0.85, 0, 0.85), Math.PI / 4);
  const rotation = new THREE.Matrix4().makeRotationFromQuaternion(quaternion);

  // OUTER
  const outerGeometry = new THREE.BoxGeometry(45, 45, 45);
  outerGeometry.applyMatrix(rotation);
  const transparentMaterial = new Material({
    color: colors.white,
    transparent: true,
    opacity: 0
  });
  const outer = new THREE.Mesh(outerGeometry, transparentMaterial);
  outer.castShadow = true;
  fascinator.add(outer);

  // INNER
  const innerGeometry = new THREE.BoxGeometry(12, 12, 12);
  const solidMaterial = new Material({ color: colors.red });
  const inner = new THREE.Mesh(innerGeometry, solidMaterial);
  inner.castShadow = true;
  fascinator.add(inner);

  return { fascinator, children: { inner, outer } };
};
